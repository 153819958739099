import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ['short', 'name', 'geo']
  connect() {
    console.log("Hello, Stimulus!", this.element)
    this.load();
  }

  load() {
    fetch('/currency.json')
      .then(response => response.json())
      .then(json => this.updateCurrency(json))
  }

  updateCurrency(json) {
    console.log(this.shortTargets)
    this.shortTargets.forEach(el => {
      el.innerHTML = json['short']
    })
    this.nameTargets.forEach(el => {
      el.innerHTML = json['name']
    })
    this.geoTargets.forEach(el => {
      el.innerHTML = json['geo']
    })
  }
}
